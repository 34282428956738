<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Dados Cadastrais</div>
      </v-col>
    </v-row>
    <v-form>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">CPF do Cliente</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input id="cpf" maxlength="14" name="cpf" size="23" type="text" v-model="cpf" readonly />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Nome do Cliente
          (Completo - sem abreviações)</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input class="text-uppercase form-input-text-with-img" id="nomeCompleto" maxlength="70" name="nomeCompleto"
            size="45" type="text" v-model="nomeCompleto" />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Declaração de
          Propósitos</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row align-center">
              <input id="movimentaContaDepositoPoupanca" name="movimentaContaDepositoPoupanca" type="checkbox"
                v-model="movimentacaoContaDepositoPoupanca" />
              <label class="form-checkbox-label" for="movimentaContaDepositoPoupanca">Movimentação de Conta de
                Depósito/Poupança</label>
            </div>

            <div class="d-flex flex-row align-center">
              <input id="emprestimosFinanciamentos" name="emprestimosFinanciamentos" type="checkbox"
                v-model="emprestimosFinanciamentos" />
              <label class="form-checkbox-label" for="emprestimosFinanciamentos">Empréstimos/Financiamentos</label>
            </div>

            <div class="d-flex flex-row align-center justify-center" id="habitacional">
              <label class="form-checkbox-label">Financiamento Habitacional</label>
              <input :disabled="isHabitacionalDisabled" id="habitacionalSIM" name="habitacional" type="radio"
                v-model="financiamentoHabitacional" v-bind:value="true" />
              <label class="form-checkbox-label" for="habitacionalSIM">SIM</label>
              <input :disabled="isHabitacionalDisabled" id="habitacionalNAO" name="habitacional" type="radio"
                v-model="financiamentoHabitacional" v-bind:value="false" />
              <label class="form-checkbox-label" for="habitacionalNAO">NÃO</label>
            </div>

            <div class="d-flex flex-row align-center">
              <input id="investimentos" name="investimentos" type="checkbox" v-model="investimentos" />
              <label class="form-checkbox-label" for="investimentos">Investimentos</label>
            </div>

            <div class="d-flex flex-row align-center">
              <input id="cartaoCredito" name="cartaoCredito" type="checkbox" v-model="cartaoCredito" />
              <label class="form-checkbox-label" for="cartaoCredito">Cartão de Crédito</label>
            </div>

            <div class="d-flex flex-row align-center">
              <input id="seguroPrevidenciaCapitalizacaoConsorcios" name="seguroPrevidenciaCapitalizacaoConsorcios"
                type="checkbox" v-model="seguroPrevidenciaCapitalizacaoConsorcios" />
              <label class="form-checkbox-label" for="seguroPrevidenciaCapitalizacaoConsorcios">Seguros/Previdência
                Privada/Capitalização/Consórcios</label>
            </div>

            <div class="d-flex flex-row align-center">
              <input id="operacoesInternacionaisCambio" name="operacoesInternacionaisCambio" type="checkbox"
                v-model="operacoesInternacionaisCambio" />
              <label class="form-checkbox-label" for="operacoesInternacionaisCambio">Operações
                Internacionais/Câmbio</label>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Nome do Cliente
          (Formato Reduzido)</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input class="text-uppercase form-input-text" id="nomeReduzido" maxlength="25" name="nomeReduzido" size="45"
            type="text" v-model="nomeReduzido" />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Data de Nascimento
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="4">
          <input class="form-input-text" id="dataNascimento" maxlength="10" name="dataNascimento" size="8" type="text"
            v-mask="'##/##/####'" v-model="dataNascimento" />
        </v-col>

        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">Sexo:</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <select id="cmbSexo" name="cmbSexo" v-model="sexo">
            <option :key="item" :value="item" v-for="item in sexos">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Nacionalidade</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <select id="cmbNacionalidade" name="cmbNacionalidade" v-model="nacionalidade">
            <option :key="item.value" :value="item.value" v-for="item in nacionalidades">{{ item.text }}</option>
          </select>
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters v-if="showPaises">
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">País de Nacionalidade
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <select id="cmbPaisDeNacionalidade" name="cmbPaisDeNacionalidade" v-model="pais">
            <option :key="item.value" :value="item.value" v-for="item in paises()">{{ item.text }}</option>
          </select>
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Naturalidade</v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="4">
          <select :disabled="isNaturalidadeDisabled" id="cmbUFNaturalidade" name="cmbUFNaturalidade"
            v-model="naturalidadeUF">
            <option :key="item.sigla" :value="item.sigla" v-for="item in estados">{{ item.sigla }}</option>
          </select>
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <select :disabled="isNaturalidadeDisabled" id="cmbMunicipioNaturalidade" name="cmbMunicipioNaturalidade"
            v-model="naturalidadeMunicipio">
            <option :key="item" :value="item" v-for="item in cmbMunicipioNaturalidade">{{ item }}</option>
          </select>
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Nome do Pai</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input class="text-uppercase form-input-text" id="nomePai" maxlength="70" name="nomePai" size="45" type="text"
            v-model="nomePai" />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Nome da Mãe</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input class="text-uppercase form-input-text" id="nomeMae" maxlength="70" name="nomeMae" size="45" type="text"
            v-model="nomeMae" />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Grau de Instrução
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <select id="cmbGrauInstrucao" name="cmbGrauInstrucao" v-model="grauInstrucao">
            <option :key="item" :value="item" v-for="item in grauInstrucoes">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">PIS/NIS</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input id="pis" maxlength="11" name="pis" size="11" type="text" v-mask="masks.pis" v-model="pisNis" />
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Tipo de Documento de
          Identificação</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbTipoDocumento" name="cmbTipoDocumento" v-model="tipoDocumento">
            <option :key="item.value" :value="item.value" v-for="item in tiposDocumentos">{{ item.value }}</option>
          </select>
        </v-col>
      </v-row>

      <!-- tipo_de_documento -> documento_de_identificacao -->
      <v-row class="form-row" no-gutters v-if="isDocumentoIdentificacaoVisible">
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Subtipo do Documento
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="subTipoIdentidadeNumeroRg" name="subTipoIdentidadeNumeroRg"
            v-model="documentoIdentificacaoSubTipo">
            <option :key="item" :value="item" v-for="item in cbTipoDocumentoSubTipo">{{ item }}</option>
          </select>
        </v-col>
      </v-row>

      <!-- tipo_de_documento -> numero -->
      <v-row class="form-row" no-gutters v-if="tipoDocumento">
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          <span v-if="isPassaporte">Número do Passaporte</span>
          <span v-else-if="isCnh">Número da Carteira Nacional de Habilitação</span>
          <span v-else>Número da Identidade</span>
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input class="text-uppercase form-input-text" id="identidadeNumeroRg1" v-if="maskByType"
            name="identidadeNumeroRg" size="15" type="text" v-mask="maskByType" v-model="tipoDocumentoNumero" />
          <input id="identidadeNumeroRg2" name="identidadeNumeroRg" size="15" type="text" v-else
            v-model="tipoDocumentoNumero" />
        </v-col>
      </v-row>

      <!-- tipo_de_documento -> Órgão Emissor -->
      <v-row class="form-row" no-gutters v-if="tipoDocumento">
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Órgão Emissor</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbOrgaoEmissorRg" name="cmbOrgaoEmissorRg" v-model="tipoDocumentoOrgaoEmissor">
            <option :key="item" :value="item" v-for="item in cbTipoDocumentoOrgaoEmissores">{{ item }}</option>
          </select>
        </v-col>
      </v-row>

      <!-- tipo_de_documento -> UF -->
      <v-row class="form-row" no-gutters v-if="tipoDocumento">
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">UF</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbUFIdentidadeRg" name="cmbUFIdentidadeRg" v-model="tipoDocumentoUf">
            <option :key="item.sigla" :value="item.sigla" v-for="item in estados">{{ item.sigla }}</option>
          </select>
        </v-col>
      </v-row>

      <!-- tipo_de_documento -> PASSAPORTE -> pais de origem -->
      <v-row class="form-row" no-gutters v-if="isPassaporte">
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">País de origem</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="paisDeOrigemPassaporte" name="paisDeOrigemPassaporte" v-model="passaportePaisOrigem">
            <option :key="item.value" :value="item.value" v-for="item in paises(true)">{{ item.text }}</option>
          </select>
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters v-if="isCnh">
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Data 1ª Habilitação
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="4">
          <input class="form-input-text" id="dataPrimeiraHabilitacaoCnh" maxlength="10"
            name="dataPrimeiraHabilitacaoCnh" type="text" v-mask="'##/##/####'" v-model="cnhDataPrimeiraHabilitacao" />
        </v-col>
      </v-row>

      <!-- tipo_de_documento -> Data de Emissão / Data Fim Validade -->
      <v-row class="form-row" no-gutters v-if="tipoDocumento">
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Data de Emissão</v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="4">
          <input class="form-input-text" id="dataEmissaoIdentidadeRg" maxlength="10" name="dataEmissaoIdentidadeRg"
            type="text" v-mask="'##/##/####'" v-model="tipoDocumentoDataEmissao" />
        </v-col>

        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'text-no-wrap', 'form-fim-val-label']"
          cols="2">Data Fim Validade</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <input class="form-input-text" id="dataFimValidadeRg" maxlength="10" name="dataFimValidadeRg" type="text"
            v-mask="'##/##/####'" v-model="tipoDocumentoDataFimValidade" />
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Estado Civil</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbEstadoCivil" name="cmbEstadoCivil" v-model="estadoCivil">
            <option :key="item" :value="item" v-for="item in estadoCivilTipos">{{ item }}</option>
          </select>
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Tipo de Ocupação
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbTpOcupacao" name="cmbTpOcupacao" v-model="tipoOcupacao">
            <option :key="item" :value="item" v-for="item in tiposOcupacoes">{{ item }}</option>
          </select>
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Ocupação</v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbOcupacao" name="cmbOcupacao" v-model="ocupacao">
            <option :key="item" :value="item" v-for="item in cmbOcupacao">{{ item }}</option>
          </select>
        </v-col>
      </v-row>

      <div v-if="showDadosConjuge">
        <v-row no-gutters class="mt-3">
          <v-col class="form-header text-center">
            <div>Dados do Cônjuge</div>
          </v-col>
        </v-row>
        <v-row class="form-row" no-gutters>
          <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="3">CPF</v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="9">
            <input class="form-input-text" id="cpfConjuge" name="cpfConjuge" type="text" v-model="conjugeCpf"
              v-mask="masks.cpf" />
            <a id="btn-consultar" @click="consultarConjuge" class="btn-azul btn-padrao">Consultar</a>
          </v-col>
        </v-row>
        <v-row class="form-row" no-gutters>
          <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="3">Nome</v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="9">
            <input class="form-input-text" id="nomeCompletoConjuge" size="35" name="nomeCompletoConjuge" type="text"
              v-model="conjugeNome" />
          </v-col>
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import _ from "lodash";
import { createNamespacedHelpers } from "vuex";
import { mapCliente, mapClienteFields } from "../../../store/modules/caixaqui-clientes";
import { mapDadosGeraisFields } from "../../../store/modules/dados-gerais";
import { required } from "vuelidate/lib/validators";
import { isValid, requiredCheckIf, validatePisIf } from "../../../validators";
import { OPTIONS } from "../../../config";

const { mapGetters: mapDadosGeraisGetters } = createNamespacedHelpers("dadosGerais");

export default {
  name: "CaixaquiCadastroClientesDadosCadastrais",

  data: () => ({
    masks: OPTIONS.masks,
    style: {
      colLeft: 2,
      colRight: 10,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    validationMessages: {
      movimentacaoContaDepositoPoupanca: {
        required: "Selecione ao menos uma declaração de propósito."
      },
      emprestimosFinanciamentos: {
        required: "Selecione ao menos uma declaração de propósito."
      },
      financiamentoHabitacional: {
        required: "Selecione ao menos uma declaração de propósito."
      },
      investimentos: {
        required: "Selecione ao menos uma declaração de propósito."
      },
      seguroPrevidenciaCapitalizacaoConsorcios: {
        required: "Selecione ao menos uma declaração de propósito."
      },
      cartaoCredito: {
        required: "Selecione ao menos uma declaração de propósito."
      },
      operacoesInternacionaisCambio: {
        required: "Selecione ao menos uma declaração de propósito."
      },
      nomeCompleto: { required: "Nome inválido." },
      sexo: { required: "Selecione o Sexo." },
      dataNascimento: {
        required:
          "Não será possível continuar sem uma data de Nascimento. Pressione o botão consultar CPF ou insira a data!"
      },
      nacionalidade: { required: "Selecione uma Nacionalidade." },
      naturalidadeUF: { required: "Selecione o estado de naturalidade." },
      naturalidadeMunicipio: { required: "Selecione o município de naturalidade." },
      grauInstrucao: { required: "Selecione Grau de Instrução." },
      pisNis: { validatePis: "Dígito verificador do NIS/PIS inválido." },
      tipoDocumento: { required: "Selecione um Tipo de Documento de Identificação." },
      documentoIdentificacaoSubTipo: { required: "Selecione o SubTipo do Documento de Identificação." },
      tipoDocumentoNumero: { required: "Informe o Número do Documento de Identificação" },
      tipoDocumentoOrgaoEmissor: {
        required: "Selecione o Orgão Emissor do Documento de Identificação"
      },
      tipoDocumentoUf: { required: "Selecione a UF do Documento de Identificação" },
      cnhDataPrimeiraHabilitacao: {
        required: "Informe a Data da Primeira Habilitação da Carteira Nacional de Habilitação"
      },
      tipoDocumentoDataEmissao: { required: "Informe a Data de Emissão do Documento de Identificação" },
      tipoDocumentoDataFimValidade: { required: "Informe a Data Fim Validade do Registro " },
      estadoCivil: { required: "Selecione um estado civil." },
      tipoOcupacao: { required: "Selecione o Tipo de Ocupação." }
    }
  }),
  validations() {
    return {
      movimentacaoContaDepositoPoupanca: { required: requiredCheckIf(this.anyDeclaracaoPropositosSelected) },
      emprestimosFinanciamentos: { required: requiredCheckIf(this.anyDeclaracaoPropositosSelected) },
      financiamentoHabitacional: { required: requiredCheckIf(this.anyDeclaracaoPropositosSelected) },
      investimentos: { required: requiredCheckIf(this.anyDeclaracaoPropositosSelected) },
      seguroPrevidenciaCapitalizacaoConsorcios: { required: requiredCheckIf(this.anyDeclaracaoPropositosSelected) },
      cartaoCredito: { required: requiredCheckIf(this.anyDeclaracaoPropositosSelected) },
      operacoesInternacionaisCambio: { required: requiredCheckIf(this.anyDeclaracaoPropositosSelected) },
      nomeCompleto: { required },
      sexo: { required },
      dataNascimento: { required },
      nacionalidade: { required },
      naturalidadeUF: { required },
      naturalidadeMunicipio: { required },
      grauInstrucao: { required },
      pisNis: {
        validatePis: validatePisIf(this.pisNis)
      },
      tipoDocumento: { required },
      tipoDocumentoNumero: { required },
      tipoDocumentoOrgaoEmissor: { required },
      tipoDocumentoUf: { required },
      cnhDataPrimeiraHabilitacao: { required: requiredCheckIf(this.isCnh) },
      tipoDocumentoDataEmissao: { required },
      tipoDocumentoDataFimValidade: {
        required: requiredCheckIf(this.isPassaporte || this.isCnh)
      },
      estadoCivil: { required },
      tipoOcupacao: { required }
    };
  },
  computed: {
    ...mapClienteFields([
      "cpf",
      "nomeCompleto",
      "nomeReduzido",
      "declaracaoPropositos.emprestimosFinanciamentos",
      "declaracaoPropositos.financiamentoHabitacional",
      "declaracaoPropositos.movimentacaoContaDepositoPoupanca",
      "declaracaoPropositos.investimentos",
      "declaracaoPropositos.cartaoCredito",
      "declaracaoPropositos.seguroPrevidenciaCapitalizacaoConsorcios",
      "declaracaoPropositos.operacoesInternacionaisCambio",
      "dataNascimento",
      "sexo",
      "nacionalidade",
      "pais",
      "nomePai",
      "nomeMae",
      "grauInstrucao",
      "pisNis",
      "estadoCivil",
      "tipoOcupacao",
      "ocupacao"
    ]),
    ...mapClienteFields({
      naturalidadeUF: "naturalidade.uf",
      naturalidadeMunicipio: "naturalidade.municipio",
      tipoDocumento: "tipoDocumento.value",
      tipoDocumentoNumero: "tipoDocumento.numero",
      tipoDocumentoOrgaoEmissor: "tipoDocumento.orgaoEmissor",
      tipoDocumentoUf: "tipoDocumento.uf",
      tipoDocumentoDataEmissao: "tipoDocumento.dataEmissao",
      tipoDocumentoDataFimValidade: "tipoDocumento.dataFimValidade",
      documentoIdentificacaoSubTipo: "tipoDocumento.documentoIdentificacao.subTipo",
      passaportePaisOrigem: "tipoDocumento.passaporte.paisOrigem",
      cnhDataPrimeiraHabilitacao: "tipoDocumento.dataPrimeiraHabilitacao", //"tipoDocumento.cnh.dataPrimeiraHabilitacao"
      conjugeCpf: "conjuge.cpf",
      conjugeNome: "conjuge.nome"
    }),
    ...mapCliente(["readOnlyDossie"]),
    ...mapDadosGeraisFields(["sexos", "nacionalidades", "grauInstrucoes", "tiposDocumentos", "estadoCivilTipos"]),
    ...mapDadosGeraisGetters([
      "estados",
      "cidades",
      "paises",
      "tipoDocumentoSubTipo",
      "tipoDocumentoSubTipoOrgaoEmissores",
      "tiposOcupacoes",
      "ocupacaoPorTipo"
    ]),
    isNaturalidadeDisabled() {
      return this.nacionalidade !== "BRASILEIRA";
    },
    showPaises() {
      return this.nacionalidade === "ESTRANGEIRO";
    },
    isHabitacionalDisabled() {
      return !this.emprestimosFinanciamentos;
    },
    cmbMunicipioNaturalidade() {
      return this.cidades(this.naturalidadeUF);
    },
    isDocumentoIdentificacaoVisible() {
      return this.tipoDocumento === "DOCUMENTO DE IDENTIFICAÇÃO";
    },
    isPassaporte() {
      return this.tipoDocumento === "PASSAPORTE";
    },
    isCnh() {
      return this.tipoDocumento === "CNH - CARTEIRA NACIONAL DE HABILITAÇÃO";
    },
    isRG() {
      return (
        this.tipoDocumento === "DOCUMENTO DE IDENTIFICAÇÃO" &&
        this.documentoIdentificacaoSubTipo === "CARTEIRA DE IDENTIDADE"
      );
    },
    cbTipoDocumentoSubTipo() {
      return this.tipoDocumentoSubTipo(this.tipoDocumento);
    },
    cbTipoDocumentoOrgaoEmissores() {
      return this.tipoDocumentoSubTipoOrgaoEmissores(this.tipoDocumento, this.documentoIdentificacaoSubTipo);
    },
    cmbOcupacao() {
      return this.ocupacaoPorTipo(this.tipoOcupacao);
    },
    showDadosConjuge() {
      return _.includes(this.estadoCivil, "CASADO");
    },
    anyDeclaracaoPropositosSelected() {
      return !(
        this.movimentacaoContaDepositoPoupanca ||
        this.emprestimosFinanciamentos ||
        this.financiamentoHabitacional ||
        this.investimentos ||
        this.seguroPrevidenciaCapitalizacaoConsorcios ||
        this.cartaoCredito ||
        this.operacoesInternacionaisCambio
      );
    },
    maskByType() {
      if (this.isCnh) {
        return this.masks.cnh;
      }

      // if (this.isRG) {
      //   return this.masks.rg;
      // }

      return null;
    }
  },
  mounted() {
    if (!_.isNil(this.readOnlyDossie) && !_.isNil(this.readOnlyDossie.clientes)) {
      const clienteDossie = this.readOnlyDossie.clientes.find(r => r.cpf === this.cpf);

      if (!_.isNil(clienteDossie)) {
        if (_.isNil(this.nomeCompleto)) {
          this.nomeCompleto = clienteDossie.nomeCompleto;
        }

        if (_.isNil(this.nomeMae)) {
          this.nomeMae = clienteDossie.nomeMae;
        }

        if (_.isNil(this.dataNascimento)) {
          this.dataNascimento = clienteDossie.dataNascimento;
        }
      }
    }
  },
  watch: {
    emprestimosFinanciamentos: function (val) {
      if (!val) {
        // noinspection JSUnusedGlobalSymbols
        this.financiamentoHabitacional = null;
      }
    },
    nacionalidade: function () {
      // noinspection JSUnusedGlobalSymbols
      this.naturalidadeUF = null;
      this.pais = null;
    },
    naturalidadeUF: function () {
      // noinspection JSUnusedGlobalSymbols
      this.naturalidadeMunicipio = null;
    }
  },
  methods: {
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        if (isValid(this.$v, this.validationMessages)) {
          this.$router.push(to);
        }
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    consultarConjuge() {
      // NAO FAZER NADA
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css">
</style>
<style scoped>
#cpf {
  width: 170px;
  margin-right: 4px;
}

#habitacional {
  margin-left: 50px;
}

#pis {
  width: 120px;
}

#cpfConjuge {
  width: 120px;
}

#nomeCompletoConjuge {
  width: 100%;
}

.form-fim-val-label {
  padding-left: 1px;
}
</style>
