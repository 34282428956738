var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Dados Cadastrais")])
          ])
        ],
        1
      ),
      _c(
        "v-form",
        [
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("CPF do Cliente")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cpf,
                        expression: "cpf"
                      }
                    ],
                    attrs: {
                      id: "cpf",
                      maxlength: "14",
                      name: "cpf",
                      size: "23",
                      type: "text",
                      readonly: ""
                    },
                    domProps: { value: _vm.cpf },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.cpf = $event.target.value
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [
                  _vm._v(
                    "Nome do Cliente\n        (Completo - sem abreviações)"
                  )
                ]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.nomeCompleto,
                        expression: "nomeCompleto"
                      }
                    ],
                    staticClass: "text-uppercase form-input-text-with-img",
                    attrs: {
                      id: "nomeCompleto",
                      maxlength: "70",
                      name: "nomeCompleto",
                      size: "45",
                      type: "text"
                    },
                    domProps: { value: _vm.nomeCompleto },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.nomeCompleto = $event.target.value
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Declaração de\n        Propósitos")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.movimentacaoContaDepositoPoupanca,
                            expression: "movimentacaoContaDepositoPoupanca"
                          }
                        ],
                        attrs: {
                          id: "movimentaContaDepositoPoupanca",
                          name: "movimentaContaDepositoPoupanca",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.movimentacaoContaDepositoPoupanca
                          )
                            ? _vm._i(
                                _vm.movimentacaoContaDepositoPoupanca,
                                null
                              ) > -1
                            : _vm.movimentacaoContaDepositoPoupanca
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.movimentacaoContaDepositoPoupanca,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.movimentacaoContaDepositoPoupanca = $$a.concat(
                                    [$$v]
                                  ))
                              } else {
                                $$i > -1 &&
                                  (_vm.movimentacaoContaDepositoPoupanca = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.movimentacaoContaDepositoPoupanca = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: { for: "movimentaContaDepositoPoupanca" }
                        },
                        [
                          _vm._v(
                            "Movimentação de Conta de\n              Depósito/Poupança"
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.emprestimosFinanciamentos,
                            expression: "emprestimosFinanciamentos"
                          }
                        ],
                        attrs: {
                          id: "emprestimosFinanciamentos",
                          name: "emprestimosFinanciamentos",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.emprestimosFinanciamentos)
                            ? _vm._i(_vm.emprestimosFinanciamentos, null) > -1
                            : _vm.emprestimosFinanciamentos
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.emprestimosFinanciamentos,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.emprestimosFinanciamentos = $$a.concat([
                                    $$v
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.emprestimosFinanciamentos = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.emprestimosFinanciamentos = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: { for: "emprestimosFinanciamentos" }
                        },
                        [_vm._v("Empréstimos/Financiamentos")]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row align-center justify-center",
                        attrs: { id: "habitacional" }
                      },
                      [
                        _c("label", { staticClass: "form-checkbox-label" }, [
                          _vm._v("Financiamento Habitacional")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.financiamentoHabitacional,
                              expression: "financiamentoHabitacional"
                            }
                          ],
                          attrs: {
                            disabled: _vm.isHabitacionalDisabled,
                            id: "habitacionalSIM",
                            name: "habitacional",
                            type: "radio"
                          },
                          domProps: {
                            value: true,
                            checked: _vm._q(_vm.financiamentoHabitacional, true)
                          },
                          on: {
                            change: function($event) {
                              _vm.financiamentoHabitacional = true
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "form-checkbox-label",
                            attrs: { for: "habitacionalSIM" }
                          },
                          [_vm._v("SIM")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.financiamentoHabitacional,
                              expression: "financiamentoHabitacional"
                            }
                          ],
                          attrs: {
                            disabled: _vm.isHabitacionalDisabled,
                            id: "habitacionalNAO",
                            name: "habitacional",
                            type: "radio"
                          },
                          domProps: {
                            value: false,
                            checked: _vm._q(
                              _vm.financiamentoHabitacional,
                              false
                            )
                          },
                          on: {
                            change: function($event) {
                              _vm.financiamentoHabitacional = false
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "form-checkbox-label",
                            attrs: { for: "habitacionalNAO" }
                          },
                          [_vm._v("NÃO")]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.investimentos,
                            expression: "investimentos"
                          }
                        ],
                        attrs: {
                          id: "investimentos",
                          name: "investimentos",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.investimentos)
                            ? _vm._i(_vm.investimentos, null) > -1
                            : _vm.investimentos
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.investimentos,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.investimentos = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.investimentos = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.investimentos = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: { for: "investimentos" }
                        },
                        [_vm._v("Investimentos")]
                      )
                    ]),
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cartaoCredito,
                            expression: "cartaoCredito"
                          }
                        ],
                        attrs: {
                          id: "cartaoCredito",
                          name: "cartaoCredito",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.cartaoCredito)
                            ? _vm._i(_vm.cartaoCredito, null) > -1
                            : _vm.cartaoCredito
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.cartaoCredito,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.cartaoCredito = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.cartaoCredito = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.cartaoCredito = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: { for: "cartaoCredito" }
                        },
                        [_vm._v("Cartão de Crédito")]
                      )
                    ]),
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.seguroPrevidenciaCapitalizacaoConsorcios,
                            expression:
                              "seguroPrevidenciaCapitalizacaoConsorcios"
                          }
                        ],
                        attrs: {
                          id: "seguroPrevidenciaCapitalizacaoConsorcios",
                          name: "seguroPrevidenciaCapitalizacaoConsorcios",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.seguroPrevidenciaCapitalizacaoConsorcios
                          )
                            ? _vm._i(
                                _vm.seguroPrevidenciaCapitalizacaoConsorcios,
                                null
                              ) > -1
                            : _vm.seguroPrevidenciaCapitalizacaoConsorcios
                        },
                        on: {
                          change: function($event) {
                            var $$a =
                                _vm.seguroPrevidenciaCapitalizacaoConsorcios,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.seguroPrevidenciaCapitalizacaoConsorcios = $$a.concat(
                                    [$$v]
                                  ))
                              } else {
                                $$i > -1 &&
                                  (_vm.seguroPrevidenciaCapitalizacaoConsorcios = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.seguroPrevidenciaCapitalizacaoConsorcios = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: {
                            for: "seguroPrevidenciaCapitalizacaoConsorcios"
                          }
                        },
                        [
                          _vm._v(
                            "Seguros/Previdência\n              Privada/Capitalização/Consórcios"
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.operacoesInternacionaisCambio,
                            expression: "operacoesInternacionaisCambio"
                          }
                        ],
                        attrs: {
                          id: "operacoesInternacionaisCambio",
                          name: "operacoesInternacionaisCambio",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.operacoesInternacionaisCambio
                          )
                            ? _vm._i(_vm.operacoesInternacionaisCambio, null) >
                              -1
                            : _vm.operacoesInternacionaisCambio
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.operacoesInternacionaisCambio,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.operacoesInternacionaisCambio = $$a.concat(
                                    [$$v]
                                  ))
                              } else {
                                $$i > -1 &&
                                  (_vm.operacoesInternacionaisCambio = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.operacoesInternacionaisCambio = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: { for: "operacoesInternacionaisCambio" }
                        },
                        [
                          _vm._v(
                            "Operações\n              Internacionais/Câmbio"
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Nome do Cliente\n        (Formato Reduzido)")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.nomeReduzido,
                        expression: "nomeReduzido"
                      }
                    ],
                    staticClass: "text-uppercase form-input-text",
                    attrs: {
                      id: "nomeReduzido",
                      maxlength: "25",
                      name: "nomeReduzido",
                      size: "45",
                      type: "text"
                    },
                    domProps: { value: _vm.nomeReduzido },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.nomeReduzido = $event.target.value
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Data de Nascimento\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: [..._vm.style.colRightClasses, "form-col-left"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "##/##/####",
                        expression: "'##/##/####'"
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dataNascimento,
                        expression: "dataNascimento"
                      }
                    ],
                    staticClass: "form-input-text",
                    attrs: {
                      id: "dataNascimento",
                      maxlength: "10",
                      name: "dataNascimento",
                      size: "8",
                      type: "text"
                    },
                    domProps: { value: _vm.dataNascimento },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.dataNascimento = $event.target.value
                      }
                    }
                  })
                ]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
                },
                [_vm._v("Sexo:")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sexo,
                          expression: "sexo"
                        }
                      ],
                      attrs: { id: "cmbSexo", name: "cmbSexo" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.sexo = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.sexos, function(item) {
                      return _c(
                        "option",
                        { key: item, domProps: { value: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Nacionalidade")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.nacionalidade,
                          expression: "nacionalidade"
                        }
                      ],
                      attrs: {
                        id: "cmbNacionalidade",
                        name: "cmbNacionalidade"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.nacionalidade = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.nacionalidades, function(item) {
                      return _c(
                        "option",
                        { key: item.value, domProps: { value: item.value } },
                        [_vm._v(_vm._s(item.text))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          ),
          _vm.showPaises
            ? _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("País de Nacionalidade\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.pais,
                              expression: "pais"
                            }
                          ],
                          attrs: {
                            id: "cmbPaisDeNacionalidade",
                            name: "cmbPaisDeNacionalidade"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.pais = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.paises(), function(item) {
                          return _c(
                            "option",
                            {
                              key: item.value,
                              domProps: { value: item.value }
                            },
                            [_vm._v(_vm._s(item.text))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Naturalidade")]
              ),
              _c(
                "v-col",
                {
                  class: [..._vm.style.colRightClasses, "form-col-left"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.naturalidadeUF,
                          expression: "naturalidadeUF"
                        }
                      ],
                      attrs: {
                        disabled: _vm.isNaturalidadeDisabled,
                        id: "cmbUFNaturalidade",
                        name: "cmbUFNaturalidade"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.naturalidadeUF = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.estados, function(item) {
                      return _c(
                        "option",
                        { key: item.sigla, domProps: { value: item.sigla } },
                        [_vm._v(_vm._s(item.sigla))]
                      )
                    }),
                    0
                  )
                ]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.naturalidadeMunicipio,
                          expression: "naturalidadeMunicipio"
                        }
                      ],
                      attrs: {
                        disabled: _vm.isNaturalidadeDisabled,
                        id: "cmbMunicipioNaturalidade",
                        name: "cmbMunicipioNaturalidade"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.naturalidadeMunicipio = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.cmbMunicipioNaturalidade, function(item) {
                      return _c(
                        "option",
                        { key: item, domProps: { value: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Nome do Pai")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.nomePai,
                        expression: "nomePai"
                      }
                    ],
                    staticClass: "text-uppercase form-input-text",
                    attrs: {
                      id: "nomePai",
                      maxlength: "70",
                      name: "nomePai",
                      size: "45",
                      type: "text"
                    },
                    domProps: { value: _vm.nomePai },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.nomePai = $event.target.value
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Nome da Mãe")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.nomeMae,
                        expression: "nomeMae"
                      }
                    ],
                    staticClass: "text-uppercase form-input-text",
                    attrs: {
                      id: "nomeMae",
                      maxlength: "70",
                      name: "nomeMae",
                      size: "45",
                      type: "text"
                    },
                    domProps: { value: _vm.nomeMae },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.nomeMae = $event.target.value
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Grau de Instrução\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.grauInstrucao,
                          expression: "grauInstrucao"
                        }
                      ],
                      attrs: {
                        id: "cmbGrauInstrucao",
                        name: "cmbGrauInstrucao"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.grauInstrucao = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.grauInstrucoes, function(item) {
                      return _c(
                        "option",
                        { key: item, domProps: { value: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("PIS/NIS")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.masks.pis,
                        expression: "masks.pis"
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pisNis,
                        expression: "pisNis"
                      }
                    ],
                    attrs: {
                      id: "pis",
                      maxlength: "11",
                      name: "pis",
                      size: "11",
                      type: "text"
                    },
                    domProps: { value: _vm.pisNis },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.pisNis = $event.target.value
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Tipo de Documento de\n        Identificação")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tipoDocumento,
                          expression: "tipoDocumento"
                        }
                      ],
                      attrs: {
                        id: "cmbTipoDocumento",
                        name: "cmbTipoDocumento"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.tipoDocumento = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.tiposDocumentos, function(item) {
                      return _c(
                        "option",
                        { key: item.value, domProps: { value: item.value } },
                        [_vm._v(_vm._s(item.value))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          ),
          _vm.isDocumentoIdentificacaoVisible
            ? _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("Subtipo do Documento\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.documentoIdentificacaoSubTipo,
                              expression: "documentoIdentificacaoSubTipo"
                            }
                          ],
                          attrs: {
                            id: "subTipoIdentidadeNumeroRg",
                            name: "subTipoIdentidadeNumeroRg"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.documentoIdentificacaoSubTipo = $event.target
                                .multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.cbTipoDocumentoSubTipo, function(item) {
                          return _c(
                            "option",
                            { key: item, domProps: { value: item } },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.tipoDocumento
            ? _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [
                      _vm.isPassaporte
                        ? _c("span", [_vm._v("Número do Passaporte")])
                        : _vm.isCnh
                        ? _c("span", [
                            _vm._v("Número da Carteira Nacional de Habilitação")
                          ])
                        : _c("span", [_vm._v("Número da Identidade")])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _vm.maskByType
                        ? _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: _vm.maskByType,
                                expression: "maskByType"
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tipoDocumentoNumero,
                                expression: "tipoDocumentoNumero"
                              }
                            ],
                            staticClass: "text-uppercase form-input-text",
                            attrs: {
                              id: "identidadeNumeroRg1",
                              name: "identidadeNumeroRg",
                              size: "15",
                              type: "text"
                            },
                            domProps: { value: _vm.tipoDocumentoNumero },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.tipoDocumentoNumero = $event.target.value
                              }
                            }
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tipoDocumentoNumero,
                                expression: "tipoDocumentoNumero"
                              }
                            ],
                            attrs: {
                              id: "identidadeNumeroRg2",
                              name: "identidadeNumeroRg",
                              size: "15",
                              type: "text"
                            },
                            domProps: { value: _vm.tipoDocumentoNumero },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.tipoDocumentoNumero = $event.target.value
                              }
                            }
                          })
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.tipoDocumento
            ? _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("Órgão Emissor")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tipoDocumentoOrgaoEmissor,
                              expression: "tipoDocumentoOrgaoEmissor"
                            }
                          ],
                          attrs: {
                            id: "cmbOrgaoEmissorRg",
                            name: "cmbOrgaoEmissorRg"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.tipoDocumentoOrgaoEmissor = $event.target
                                .multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.cbTipoDocumentoOrgaoEmissores, function(
                          item
                        ) {
                          return _c(
                            "option",
                            { key: item, domProps: { value: item } },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.tipoDocumento
            ? _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("UF")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tipoDocumentoUf,
                              expression: "tipoDocumentoUf"
                            }
                          ],
                          attrs: {
                            id: "cmbUFIdentidadeRg",
                            name: "cmbUFIdentidadeRg"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.tipoDocumentoUf = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.estados, function(item) {
                          return _c(
                            "option",
                            {
                              key: item.sigla,
                              domProps: { value: item.sigla }
                            },
                            [_vm._v(_vm._s(item.sigla))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isPassaporte
            ? _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("País de origem")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passaportePaisOrigem,
                              expression: "passaportePaisOrigem"
                            }
                          ],
                          attrs: {
                            id: "paisDeOrigemPassaporte",
                            name: "paisDeOrigemPassaporte"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.passaportePaisOrigem = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.paises(true), function(item) {
                          return _c(
                            "option",
                            {
                              key: item.value,
                              domProps: { value: item.value }
                            },
                            [_vm._v(_vm._s(item.text))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isCnh
            ? _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("Data 1ª Habilitação\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [..._vm.style.colRightClasses, "form-col-left"],
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cnhDataPrimeiraHabilitacao,
                            expression: "cnhDataPrimeiraHabilitacao"
                          }
                        ],
                        staticClass: "form-input-text",
                        attrs: {
                          id: "dataPrimeiraHabilitacaoCnh",
                          maxlength: "10",
                          name: "dataPrimeiraHabilitacaoCnh",
                          type: "text"
                        },
                        domProps: { value: _vm.cnhDataPrimeiraHabilitacao },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.cnhDataPrimeiraHabilitacao = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.tipoDocumento
            ? _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("Data de Emissão")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [..._vm.style.colRightClasses, "form-col-left"],
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tipoDocumentoDataEmissao,
                            expression: "tipoDocumentoDataEmissao"
                          }
                        ],
                        staticClass: "form-input-text",
                        attrs: {
                          id: "dataEmissaoIdentidadeRg",
                          maxlength: "10",
                          name: "dataEmissaoIdentidadeRg",
                          type: "text"
                        },
                        domProps: { value: _vm.tipoDocumentoDataEmissao },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.tipoDocumentoDataEmissao = $event.target.value
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [
                        ..._vm.style.colLeftClasses,
                        "text-no-wrap",
                        "form-fim-val-label"
                      ],
                      attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
                    },
                    [_vm._v("Data Fim Validade")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tipoDocumentoDataFimValidade,
                            expression: "tipoDocumentoDataFimValidade"
                          }
                        ],
                        staticClass: "form-input-text",
                        attrs: {
                          id: "dataFimValidadeRg",
                          maxlength: "10",
                          name: "dataFimValidadeRg",
                          type: "text"
                        },
                        domProps: { value: _vm.tipoDocumentoDataFimValidade },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.tipoDocumentoDataFimValidade =
                              $event.target.value
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Estado Civil")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.estadoCivil,
                          expression: "estadoCivil"
                        }
                      ],
                      attrs: { id: "cmbEstadoCivil", name: "cmbEstadoCivil" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.estadoCivil = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.estadoCivilTipos, function(item) {
                      return _c(
                        "option",
                        { key: item, domProps: { value: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Tipo de Ocupação\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tipoOcupacao,
                          expression: "tipoOcupacao"
                        }
                      ],
                      attrs: { id: "cmbTpOcupacao", name: "cmbTpOcupacao" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.tipoOcupacao = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.tiposOcupacoes, function(item) {
                      return _c(
                        "option",
                        { key: item, domProps: { value: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("Ocupação")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ocupacao,
                          expression: "ocupacao"
                        }
                      ],
                      attrs: { id: "cmbOcupacao", name: "cmbOcupacao" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.ocupacao = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.cmbOcupacao, function(item) {
                      return _c(
                        "option",
                        { key: item, domProps: { value: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          ),
          _vm.showDadosConjuge
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { staticClass: "form-header text-center" }, [
                        _c("div", [_vm._v("Dados do Cônjuge")])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "form-row", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colLeftClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "3"
                          }
                        },
                        [_vm._v("CPF")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "9"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.conjugeCpf,
                                expression: "conjugeCpf"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: _vm.masks.cpf,
                                expression: "masks.cpf"
                              }
                            ],
                            staticClass: "form-input-text",
                            attrs: {
                              id: "cpfConjuge",
                              name: "cpfConjuge",
                              type: "text"
                            },
                            domProps: { value: _vm.conjugeCpf },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.conjugeCpf = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "btn-azul btn-padrao",
                              attrs: { id: "btn-consultar" },
                              on: { click: _vm.consultarConjuge }
                            },
                            [_vm._v("Consultar")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "form-row", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colLeftClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "3"
                          }
                        },
                        [_vm._v("Nome")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "9"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.conjugeNome,
                                expression: "conjugeNome"
                              }
                            ],
                            staticClass: "form-input-text",
                            attrs: {
                              id: "nomeCompletoConjuge",
                              size: "35",
                              name: "nomeCompletoConjuge",
                              type: "text"
                            },
                            domProps: { value: _vm.conjugeNome },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.conjugeNome = $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }